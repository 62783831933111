import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";

export const getReportAging = async (req: PaginationReq, download?: string | null) => {
    var params: Record<string, string> = {
        page: req.page.toString(),
        type: req.type!
    }
    if (download) {
        params["download"] = "1"
    }
    return await customFetch(`Report/ReportAging?${new URLSearchParams(params)}`)

}
export const getInvoiceByCustomer = async (download?: string | null) => {
    var params: Record<string, string> = {

    }
    if (download) {
        params["download"] = "1"
    }
    return await customFetch(`Report/InvoiceByCustomer?${new URLSearchParams(params)}`)

}
export const getBillByVendor = async (download?: string | null) => {
    var params: Record<string, string> = {

    }
    if (download) {
        params["download"] = "1"
    }
    return await customFetch(`Report/BillByVendor?${new URLSearchParams(params)}`)

}
export const getCashflow = async (date: DateRange) => {

    return await customFetch(`Report/Cashflow`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getProfitLoss = async (date: DateRange) => {

    return await customFetch(`Report/ProfitLoss`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getCogs = async (date: DateRange) => {

    return await customFetch(`Report/Cogs`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getJournalSupply = async (date: DateRange) => {

    return await customFetch(`Report/JournalSupply`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getGeneralLedger = async (date: DateRange, download?: boolean) => {

    return await customFetch(`Report/GeneralLedger${download ? '?download=1' : ''}`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getQuarterlyReport = async (date: Date, download?: boolean) => {

    return await customFetch(`Report/QuarterlyReport?year=${moment(date).format('YYYY')}${download ? '&download=1' : ''}`, {
    })

}
export const getAccountSupply = async (date: DateRange) => {

    return await customFetch(`Report/AccountSupply`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getCapitalChange = async (date: DateRange) => {

    return await customFetch(`Report/CapitalChange`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getBalanceSheet = async (date: DateRange) => {

    return await customFetch(`Report/BalanceSheet`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const getTrialBalance = async (date: DateRange) => {

    return await customFetch(`Report/TrialBalance`, {
        method: "POST",
        body: JSON.stringify({
            start_date: moment(date[0]).local().startOf("day").toISOString(true),
            end_date: moment(date[1]).local().endOf("day").toISOString(true),
        })
    })

}
export const downloadCashflow = async (path: string, date: DateRange) => {
    var params: Record<string, string> = {
        start_date: moment(date[0]).local().startOf("day").toISOString(true),
        end_date: moment(date[1]).local().endOf("day").toISOString(true),
    }

    return await customFetch(`Report/Cashflow/${path}?${new URLSearchParams(params)}`)

}
export const downloadBalanceSheet = async (path: string, date: DateRange) => {
    var params: Record<string, string> = {
        start_date: moment(date[0]).local().startOf("day").toISOString(true),
        end_date: moment(date[1]).local().endOf("day").toISOString(true),
    }

    return await customFetch(`Report/BalanceSheet/${path}?${new URLSearchParams(params)}`)

}
export const downloadTrialBalance = async (path: string, date: DateRange) => {
    var params: Record<string, string> = {
        start_date: moment(date[0]).local().startOf("day").toISOString(true),
        end_date: moment(date[1]).local().endOf("day").toISOString(true),
    }

    return await customFetch(`Report/TrialBadownloadTrialBalance/${path}?${new URLSearchParams(params)}`)

}
export const downloadProfitLoss = async (path: string, date: DateRange) => {
    var params: Record<string, string> = {
        start_date: moment(date[0]).local().startOf("day").toISOString(true),
        end_date: moment(date[1]).local().endOf("day").toISOString(true),
    }

    return await customFetch(`Report/ProfitLoss/${path}?${new URLSearchParams(params)}`)

}
export const downloadCapitalChange = async (path: string, date: DateRange) => {
    var params: Record<string, string> = {
        start_date: moment(date[0]).local().startOf("day").toISOString(true),
        end_date: moment(date[1]).local().endOf("day").toISOString(true),
    }

    return await customFetch(`Report/CapitalChange/${path}?${new URLSearchParams(params)}`)

}