import { useEffect, useState, type FC } from 'react';
import { validationUser } from './repositories/login';
import { Notification } from "rsuite";
import { Link, useParams } from "react-router-dom";
interface VerificationProps {}

const Verification: FC<VerificationProps> = ({}) => {
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);
    const { code } = useParams();
    const [success, setSuccess] = useState("");
    const [failed, setFailed] = useState("");
    useEffect(() => {
      setMounted(true);
    }, []);
  
    useEffect(() => {
      if (!mounted) return;
      validation();
    }, [mounted]);
  
    const validation = async () => {
      try {
        setLoading(true);
        await validationUser(code!);
        setSuccess("Validasi berhasil silahkan login");
      } catch (error) {
        setFailed(`Validasi user tidak berhasil; ${error}`);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div>
        <div className="@container pt-32 mb-16">
          <div className="@[480px]:px-4 @[480px]:py-3">
            <img src="/images/logo.png" alt="" className="m-auto w-32" />
          </div>
        </div>
        <div>
          
          <section className=" ">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div className="p-6">
                {success && (
                  <Notification type="success" header="Berhasil">
                    {success}
                    <p>
                      <Link to="/">Klik Disini</Link>
                    </p>
                  </Notification>
                )}
                {failed && (
                  <Notification type="error" header="Gagal">
                    {failed}
                  </Notification>
                )}
              </div>
            </div>
          </section>
        </div>
        </div>
    );
}
export default Verification;

