import { AccountReq, EditAccountReq } from "../model/account";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";

export let getType = async () => {
    return await customFetch(`Account/Type`)
}
export let getAccountTemplates = async () => {
    return await customFetch(`Account/Template`)
}

export let getCashflowSubGroup = async () => {
    return await customFetch(`Account/CashflowGroup`)
}
export let generateCode = async (accountType: string, cashFlowSubGroup?: string) => {
    var params: Record<string, string> = {
        type: accountType,
    };
    if (cashFlowSubGroup) {
        params["cashflow_sub_group"] = cashFlowSubGroup
    }
    return await customFetch(`Account/GenerateCode?${new URLSearchParams(params)}`)
}

export let addAccount = async (req: AccountReq) => {
    return await customFetch(`Account/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let editAccount = async (id: string, req: EditAccountReq) => {
    return await customFetch(`Account/${id}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let generateAccountReport = async (uuid: string, dateRange?: DateRange | null, download?: string | null) => {
    var params: Record<string, string> = {

    };
    if (dateRange) {
        params["start_date"] = moment(dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(dateRange[1]).format("YYYY-MM-DD")
    }

    if (download) {
        if (download == "xls") {
            params["is_xls"] = "1"
        }
        if (download == "pdf") {
            params["is_pdf"] = "1"
        }
    }

    return await customFetch(`Account/${uuid}/GenerateReport?${new URLSearchParams(params)}`)
}
export let deleteAccount = async (uuid: string) => {
    return await customFetch(`Account/${uuid}`, {
        method: "DELETE",
    })
}

export let getAccountDetail = async (uuid: string) => {
    return await customFetch(`Account/${uuid}`)

}
export let getAccounts = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search) {
        params["search"] = pagination.search
    }

    if (pagination.type) {
        params["type"] = pagination.type
    }
    
    if (pagination.isTax != null) {
        params["is_tax"] = pagination.isTax ? "1" : "0"
    }
    if (pagination.cashflowGroup) {
        params["cashflow_group"] = pagination.cashflowGroup
    }
    if (pagination.cashflowSubgroup) {
        params["cashflow_sub_group"] = pagination.cashflowSubgroup
    }
    if (pagination.category) {
        params["category"] = pagination.category
    }
    return await customFetch(`Account/?${new URLSearchParams(params)}`)
}