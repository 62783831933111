
export let PERMISSIONS = "permissions"
export let TOKEN = "token"
export let CURRENCY = "currency"
export let COMPANIES = "companies"
export let SELECTED_COMPANY_ID = "selected-company-id"
export let SELECTED_MACHINE = "selected-machine"
export let SELECTED_MERCHANT_ID = "selected-merchant-id"
export let SELECTED_MERCHANT = "selected-merchant"
export let SELECTED_COMPANY = "selected-company"
export let DATERANGE = "daterange"
export let BOOKMARKS = "bookmarks"
export let END_DATE = "end-date"
export let SAVED_SALES = "saved-sales"
export let PROFILE = "profile"
export let DAILY = "daily"
export let WEEKLY = "weekly"
export let MONTHLY = "monthly"
export let YEARLY = "yearly"