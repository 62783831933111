import { ReactNode, useEffect, useState, type FC } from "react";
import { chatMessage, hint } from "../objects/chatMessage";
import { Profile } from "../model/profile";
import useTypewriter from "../hooks/useTypewriter";
import { IoAttachOutline, IoCopyOutline } from "react-icons/io5";
import { money, parseTime, randomStr } from "../utils/helper";
import { TbCloudDownload, TbInvoice } from "react-icons/tb";
import { quotationDownload } from "../repositories/quotation";
import { errorToast, successToast } from "../utils/helper-ui";
import { saveAs } from "file-saver";
import moment from "moment";
import { purchaseOrderDownload } from "../repositories/purchase_order";
import { invoiceDownload } from "../repositories/invoice";
import { billDownload } from "../repositories/bill";
import { BsCardChecklist, BsQuestionCircle, BsTrash } from "react-icons/bs";
import { RiLightbulbLine } from "react-icons/ri";
import { BiBookmark, BiCommand, BiSolidBookmark } from "react-icons/bi";
import HintBox from "./HintBox";
import {
  downloadCashflow,
  downloadProfitLoss,
  getQuarterlyReport,
} from "../repositories/report";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { generateAccountReport } from "../repositories/account";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { Button } from "rsuite";
import { changePassword } from "../repositories/my";
import { postForgot } from "../repositories/login";
import Chart from "react-apexcharts";
import { productChart } from "../objects/chart";

interface ChatBoxProps {
  profile: Profile;
  messages: chatMessage[];
  onCopy: (s: string) => void;
  onLoading: (b: boolean) => void;
  onDelete: (msg: chatMessage) => void;
  onHint: (h: hint) => void;
  onBookmark: (msg: chatMessage) => void;
}

const ChatBox: FC<ChatBoxProps> = ({
  profile,
  messages,
  onCopy,
  onLoading,
  onDelete,
  onHint,
  onBookmark,
}) => {
  const footer = (msg: chatMessage) => {
    if (msg.isUser) return;
    switch (msg.type) {
      case "get_best_selling_products":
        return (
          <Chart
            series={msg.data.map((e: productChart) => e.sold)}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                id: "basic-bar",
              },
              legend: {
                show: false,
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              labels: msg.data.map((e: productChart) => e.name),
            }}
            type="pie"
            width={"300px"}
            height={"300px"}
          />
        );
      case "get_purchase_sales_chart":
        return (
          <Chart
            height={"300px"}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                id: "basic-bar",
              },
              legend: {
                show: false,
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              xaxis: {
                categories: [...(msg.data.labels ?? [])],
              },
              yaxis: {
                show: false,
              },
              tooltip: {
                y: {
                  formatter(val: number) {
                    return money(val, 0);
                  },
                },
              },
            }}
            series={[
              {
                name: "Penjualan",
                data: [...(msg.data?.sales ?? []).map((e: number) => e)],
                color: "#6ebe82",
              },
              {
                name: "Pembelian",
                data: [...(msg.data?.buys ?? []).map((e: number) => e)],
                color: "#ea6e6b",
              },
            ]}
            type="line"
          />
        );
      case "get_profit_loss_chart":
        return (
          <Chart
            height={"300px"}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                id: "basic-bar",
              },
              legend: {
                show: false,
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              xaxis: {
                categories: [...(msg.data.labels ?? [])],
              },
              yaxis: {
                show: false,
              },
              tooltip: {
                y: {
                  formatter(val: number) {
                    return money(val, 0);
                  },
                },
              },
            }}
            series={[
              {
                name: "Laba Rugi",
                data: [...(msg.data?.amounts ?? []).map((e: number) => e)],
                color: "#6ebe82",
              },
            ]}
            type="line"
          />
        );
      case "change_password":
        return (
          <Button
            size="sm"
            className="mt-4"
            appearance="primary"
            color="blue"
            onClick={() => {
              onLoading(true);
              changePassword({
                old_password: "",
                new_password: msg.data.new_password,
              })
                .then(() => {
                  successToast(`Password Berhasil Di Update`);
                  onDelete(msg);
                })
                .catch((error) => {
                  errorToast(`${error}`);
                })
                .finally(() => onLoading(false));
            }}
          >
            Ya Ganti Password
          </Button>
        );
        break;
      case "reset_password":
        return (
          <Button
            size="sm"
            className="mt-4"
            appearance="primary"
            color="blue"
            onClick={() => {
              onLoading(true);
              postForgot(profile.email!)
                .then(() => {
                  successToast(`Password Berhasil Di Reset`);
                  onDelete(msg);
                })
                .catch((error) => {
                  errorToast(`${error}`);
                })
                .finally(() => onLoading(false));
            }}
          >
            Ya Reset Password
          </Button>
        );
        break;

      default:
        break;
    }
    return <div></div>;
  };
  const download = (msg: chatMessage) => {
    if (msg.isUser) return;
    switch (msg.type) {
      case "get_quarterly_report":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                onLoading(true);
                let resp = await getQuarterlyReport(
                  moment(`${msg.data.year}-01-01`).toDate(),
                  true
                );
                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `Laporan-Per-Kuartal-Tahun-${msg.data.year}-${moment().format(
                    "YYYYMMDDHHmmss"
                  )}.xlsx`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );
      case "get_account_report":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                let dateRange: DateRange = [
                  moment(0).toDate(),
                  moment().toDate(),
                ];
                if (msg.data?.start_date) {
                  dateRange[0] = moment(msg.data?.start_date).toDate();
                }
                if (msg.data?.end_date) {
                  dateRange[1] = moment(msg.data?.end_date).toDate();
                }
                onLoading(true);
                let resp = await generateAccountReport(
                  msg.lastId!,
                  dateRange,
                  "pdf"
                );
                console.log(resp.headers);
                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `Laporan-Akun-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );
      case "get_profit_loss_report":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                let dateRange: DateRange = [
                  moment(0).toDate(),
                  moment().toDate(),
                ];
                if (msg.data?.start_date) {
                  dateRange[0] = moment(msg.data?.start_date).toDate();
                }
                if (msg.data?.end_date) {
                  dateRange[1] = moment(msg.data?.end_date).toDate();
                }
                onLoading(true);
                let resp = await downloadProfitLoss("download-pdf", dateRange);

                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `Laporan-Laba-Rugi-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );

      case "get_cash_flow_report":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                let dateRange: DateRange = [
                  moment(0).toDate(),
                  moment().toDate(),
                ];
                if (msg.data?.start_date) {
                  dateRange[0] = moment(msg.data?.start_date).toDate();
                }
                if (msg.data?.end_date) {
                  dateRange[1] = moment(msg.data?.end_date).toDate();
                }
                onLoading(true);
                let resp = await downloadCashflow("download-pdf", dateRange);

                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `Laporan-Arus-Kas-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );

      case "quotation_created":
      case "quotation_detail":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                onLoading(true);
                let resp = await quotationDownload(msg.lastId!, {
                  template: "tailwind",
                  address: true,
                  payment: true,
                  header: "company",
                  notes: true,
                  download: true,
                });
                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `Quotation-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );
      case "puchase_order_created":
      case "purchase_order_detail":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                onLoading(true);
                let resp = await purchaseOrderDownload(msg.lastId!, {
                  template: "tailwind",
                  address: true,
                  payment: true,
                  header: "company",
                  notes: true,
                  download: true,
                });
                let respBlob = await resp.blob();
                saveAs(respBlob, `PO-${moment().format("YYYYMMDDHHmmss")}.pdf`);
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );
      case "invoice_created":
      case "invoice_detail":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                onLoading(true);
                let resp = await invoiceDownload(msg.lastId!, {
                  template: "tailwind",
                  address: true,
                  payment: true,
                  header: "company",
                  notes: true,
                  download: true,
                });
                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `INV-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );
      case "puchase_created":
      case "bill_detail":
        return (
          <TbCloudDownload
            size={16}
            className="   cursor-pointer"
            onClick={async () => {
              try {
                onLoading(true);
                let resp = await billDownload(msg.lastId!, {
                  template: "tailwind",
                  address: true,
                  payment: true,
                  header: "company",
                  notes: true,
                  download: true,
                });
                let respBlob = await resp.blob();
                saveAs(
                  respBlob,
                  `BILL-${moment().format("YYYYMMDDHHmmss")}.pdf`
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                onLoading(false);
              }
            }}
          />
        );

      default:
        break;
    }
    return null;
  };

  const speech = (text: string) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "id-ID"; // Atur bahasa sesuai kebutuhan
      window.speechSynthesis.speak(utterance);
    }
  };

  return (
    <>
      {(!profile || messages.length == 0) && (
        <div className="h-full w-full flex items-center justify-center">
          <div className="flex flex-col w-full">
            <div className="flex flex-col justify-center items-center w-max-[200]">
              <img src="/android-chrome-512x512.png" alt="" className="w-24" />
              <p className="my-4 text-sm w-48 text-center">
                Halo, Saya Asya apa yang bisa saya bantu hari ini ....
              </p>
            </div>
            <HintBox
              onClick={(v) => {
                if (!profile) {
                  errorToast(`Silahkan Login Terlebih Dahulu`);
                  return;
                }
                onHint(v);
              }}
            />
          </div>
        </div>
      )}
      {messages

        .filter((e) => {
          if (profile) {
            return !e.isFirst;
          }
          return e;
        })
        .map((message, index) => {
          return (
            <div
              key={index}
              className={`min-w-[40%] max-w-[90%] relative mb-4 p-3 rounded-lg ${
                message.isUser
                  ? "bg-blue-500 text-white  self-end answer-wrapper"
                  : "bg-white text-gray-700 border self-start answer-wrapper "
              }`}
            >
              {message.isUser && message.fileUrl && (
                <div>
                  {message.fileType?.includes("audio") && (
                    <audio src={message.fileUrl} controls />
                  )}
                  {(message.fileType == "jpg" ||
                    message.fileType == "jpeg" ||
                    message.fileType == "png") && (
                    <img
                      src={message.fileUrl}
                      className=" cursor-pointer aspect-square object-cover border-2 border-white rounded-xl mb-2"
                      onClick={() => window.open(message.fileUrl)}
                    />
                  )}
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: message.text }} />

              {footer(message)}
              <div className="flex justify-between flex-row items-center mt-2">
                <div className="mt-2 " style={{ fontSize: 12 }}>
                  {parseTime(message.createdAt)}
                </div>
                <div className="flex justify-end flex-row gap-2">
                  {download(message)}
                  {!message.isUser && (
                    <>
                      {message.is_bookmarked ? (
                        <BiSolidBookmark
                          className="cursor-pointer text-pink-600"
                          size={18}
                          onClick={() => onBookmark(message)}
                        />
                      ) : (
                        <BiBookmark
                          className="cursor-pointer"
                          size={18}
                          onClick={() => onBookmark(message)}
                        />
                      )}
                    </>
                  )}
                  <IoCopyOutline
                    size={16}
                    className="cursor-pointer"
                    onClick={() =>
                      message.isUser
                        ? onCopy(message.text)
                        : navigator.clipboard.writeText(message.text_md ?? "")
                    }
                  />
                  {message.isUser && message.uuid && (
                    <BsTrash
                      size={16}
                      className=" cursor-pointer"
                      onClick={() => onDelete(message)}
                    />
                  )}
                  {!message.isUser &&
                    message.uuid &&
                    "speechSynthesis" in window && (
                      <HiOutlineSpeakerWave
                        size={16}
                        className=" cursor-pointer "
                        onClick={() => {
                          if (message.text_md) speech(message.text_md);
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default ChatBox;
