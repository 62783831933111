import { PurchaseOrderReq } from "../model/purchase_order";
import { ItemReq } from "../model/item";
import { PaginationReq } from "../model/pagination";
import { PaymentReq } from "../model/transaction";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { printParams } from "../objects/print";
import { InvoiceBillEmailReq } from "../model/invoice";


export let getPurchaseOrders = async (pagination: PaginationReq, dateRange?: DateRange | null, vendorIds? : string | null, tags?: string[]) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (pagination.merchant_id) {
        params["merchant_id"] = pagination.merchant_id
    }

    if (dateRange) {
        params["start_date"] = moment(dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(dateRange[1]).format("YYYY-MM-DD")
    }
    if (vendorIds) {
        params["vendor_ids"] = vendorIds
    }

    if (tags) {
        params["tag_ids"] = tags.join(",")
    }

    return await customFetch(`PurchaseOrder/?${new URLSearchParams(params)}`)
}
export let getPurchaseOrderDetail = async (uuid: string) => {
    return await customFetch(`PurchaseOrder/${uuid}`)
}
export let addItemPurchaseOrder = async (uuid: string, req: ItemReq) => {
    return await customFetch(`PurchaseOrder/${uuid}/AddItem`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateItemPurchaseOrder = async (uuid: string, itemId: string, req: ItemReq) => {

    return await customFetch(`PurchaseOrder/${uuid}/UpdateItem/${itemId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let deleteItemPurchaseOrder = async (uuid: string, itemId: string,) => {
    return await customFetch(`PurchaseOrder/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}

export let createPurchaseOrder = async (req: PurchaseOrderReq) => {

    return await customFetch(`PurchaseOrder/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updatePurchaseOrder = async (uuid: string, req: PurchaseOrderReq) => {
    return await customFetch(`PurchaseOrder/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let addPurchaseOrderPayment = async (uuid: string, req: PaymentReq) => {
    return await customFetch(`PurchaseOrder/${uuid}/AddPayment`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let downloadPurchaseOrder = async (uuid: string, {
    hide_tax,
    hide_secondary_tax,
    hide_total_before_tax,
}: {
    hide_tax: string
    hide_secondary_tax: string
    hide_total_before_tax: string
}) => {
    return await customFetch(`PurchaseOrder/${uuid}/Download?${new URLSearchParams({ hide_tax, hide_secondary_tax, hide_total_before_tax })}`)
}
export let releasePurchaseOrder = async (uuid: string) => {
    return await customFetch(`PurchaseOrder/${uuid}/Release`, {
        method: "PUT",
    })
}
export let unreleasePurchaseOrder = async (uuid: string, password: string) => {
    return await customFetch(`PurchaseOrder/${uuid}/Unrelease`, {
        method: "PUT",
        body: JSON.stringify({ password })
    })
}
export let getReceipt = async (uuid: string, transactionId: string) => {
    return await customFetch(`PurchaseOrder/${uuid}/Payment/${transactionId}/Receipt`)
}

export let deletePurchaseOrder = async (uuid: string) => {

    return await customFetch(`PurchaseOrder/${uuid}`, {
        method: "DELETE",
    })
}


export let getPurchaseOrderGenerateNumber = async () => {
    return await customFetch(`PurchaseOrder/GenerateNumber`)
}

export let purchaseOrderDownload = async (uuid: string, printReq: printParams) => {
    var params: Record<string, string> = {
        template: printReq.template,

    };
    if (printReq.address) {
        params["address"] = "1"
    }
    if (printReq.payment) {
        params["payment"] = "1"
    }
    if (printReq.header) {
        params["header"] = printReq.header
    }
    if (printReq.notes) {
        params["notes"] = "1"
    }
    if (printReq.download) {
        params["download"] = "1"
    }
    return await customFetch(`PurchaseOrder/${uuid}/Pdf?${new URLSearchParams(params)}`)
}


export let sendMailPurchaseOrder = async (uuid: string, req: InvoiceBillEmailReq) => {
    return await customFetch(`PurchaseOrder/${uuid}/SendMail`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}