import { InvoiceBillEmailReq, InvoiceReq } from "../model/invoice";
import { ItemReq } from "../model/item";
import { PaginationReq } from "../model/pagination";
import { PaymentReq } from "../model/transaction";
import { printParams } from "../objects/print";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";


export let getInvoices = async (pagination: PaginationReq, dateRange?: DateRange | null, customerIds?: string | null, tags?: string[]) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    if (pagination.merchant_id) {
        params["merchant_id"] = pagination.merchant_id
    }
    if (dateRange) {
        params["start_date"] = moment(dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(dateRange[1]).format("YYYY-MM-DD")
    }
    if (customerIds) {
        params["customer_ids"] = customerIds
    }
    if (tags) {
        params["tag_ids"] = tags.join(",")
    }
    return await customFetch(`Invoice/?${new URLSearchParams(params)}`)
}
export let getInvoiceDetail = async (uuid: string) => {
    return await customFetch(`Invoice/${uuid}`)
}
export let getInvoiceGenerateNumber = async () => {
    return await customFetch(`Invoice/GenerateNumber`)
}
export let addItemInvoice = async (uuid: string, req: ItemReq) => {
    return await customFetch(`Invoice/${uuid}/AddItem`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateItemInvoice = async (uuid: string, itemId: string, req: ItemReq) => {

    return await customFetch(`Invoice/${uuid}/UpdateItem/${itemId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let deleteItemInvoice = async (uuid: string, itemId: string,) => {
    return await customFetch(`Invoice/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}

export let createInvoice = async (req: InvoiceReq) => {

    return await customFetch(`Invoice/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updateInvoice = async (uuid: string, req: InvoiceReq) => {
    return await customFetch(`Invoice/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let addInvoicePayment = async (uuid: string, req: PaymentReq) => {
    return await customFetch(`Invoice/${uuid}/AddPayment`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteInvoicePayment = async (uuid: string, paymentId: string) => {
    return await customFetch(`Invoice/${uuid}/DeletePayment/${paymentId}`, {
        method: "DELETE",
    })
}

export let invoiceDownload = async (uuid: string, printReq: printParams) => {
    var params: Record<string, string> = {
        template: printReq.template,

    };
    if (printReq.address) {
        params["address"] = "1"
    }
    if (printReq.payment) {
        params["payment"] = "1"
    }
    if (printReq.header) {
        params["header"] = printReq.header
    }
    if (printReq.notes) {
        params["notes"] = "1"
    }
    if (printReq.download) {
        params["download"] = "1"
    }
    return await customFetch(`Invoice/${uuid}/Pdf?${new URLSearchParams(params)}`)
}

export let downloadInvoice = async (uuid: string, {
    hide_tax,
    hide_secondary_tax,
    hide_total_before_tax,
}: {
    hide_tax: string
    hide_secondary_tax: string
    hide_total_before_tax: string
}) => {
    return await customFetch(`Invoice/${uuid}/Download?${new URLSearchParams({ hide_tax, hide_secondary_tax, hide_total_before_tax })}`)
}
export let releaseInvoice = async (uuid: string) => {
    return await customFetch(`Invoice/${uuid}/Release`, {
        method: "PUT",
    })
}
export let unreleaseInvoice = async (uuid: string, password: string) => {
    return await customFetch(`Invoice/${uuid}/Unrelease`, {
        method: "PUT",
        body: JSON.stringify({ password })
    })
}
export let duplicateInvoice = async (uuid: string) => {
    return await customFetch(`Invoice/${uuid}/Duplicate`, {
        method: "POST",
    })
}
export let sendMailInvoice = async (uuid: string, req: InvoiceBillEmailReq) => {
    return await customFetch(`Invoice/${uuid}/SendMail`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let getReceipt = async (uuid: string, transactionId: string) => {
    return await customFetch(`Invoice/${uuid}/Payment/${transactionId}/Receipt`)
}

export let deleteInvoice = async (uuid: string) => {

    return await customFetch(`Invoice/${uuid}`, {
        method: "DELETE",
    })
}