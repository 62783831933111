import { LoginGooleReq, LoginReq, RegisReq } from "../model/login";
import { customFetch } from "../utils/helper";

export let postLogin = async (req: LoginReq) => {
    return await customFetch("Login", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let postForgot = async (email: string) => {
    return await customFetch("Forgot", {
        method: "POST",
        body: JSON.stringify({username: email})
    })
}
export let postLoginGoogle = async (req: LoginGooleReq) => {
    return await customFetch("LoginWebWithGoogle", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let postRegis = async (req: RegisReq) => {
    return await customFetch("Registration", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let postRegisMiqro = async (req: RegisReq) => {
    return await customFetch("Registration/Miqro", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let validationUser = async (code: string) => {
    return await customFetch("Validation/Code/"+code, {
        method: "POST",
    })
}

