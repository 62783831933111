
import { AutoNumberSetting, BusinessSetting, CompanyReq, CurrencyActiveReq, InviteReq, MemberRoleReq, PermissionReq, ProductAddOnSetting } from "../model/company";
import { SELECTED_COMPANY_ID } from "../objects/constants";
import { asyncLocalStorage, customFetch } from "../utils/helper";


export let getCompanyMembers = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/Members`)
}
export let createCompany = async (req: CompanyReq) => {
    return await customFetch(`Company/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let getCompanyRoles = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/Roles`)
}
export let getCompanySetting = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/Setting`)
}
export let updateCompanySetting = async (type: string, data: BusinessSetting) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/Setting/${type}`, {
        method: "PUT",
        body: JSON.stringify(data)
    })
}
export let getDocSetting = async (type: string) => {
    return await customFetch(`Company/DocSetting?type=${type}`)
}
export let getFileImageUpload = async () => {
    return await customFetch(`File/ImageUpload`)
}

export let getCompanyDetail = async () => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}`)
}
export let getCompanyRolePermissions = async ({ roleId, search }: { roleId?: string, search: string }) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    var params: Record<string, string> = {
        search,
    };

    if (roleId) {
        params["role_id"] = roleId
    }
    return await customFetch(`Permission/?${new URLSearchParams(params)}`)
}
export let updateCompany = async (req: CompanyReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let createRole = async (req: PermissionReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/CreateRolePermission`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let updateRole = async (req: PermissionReq, roleId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/UpdateRolePermission/${roleId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let deleteRole = async (roleId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/DeleteRolePermission/${roleId}`, {
        method: "DELETE",
    })
}

export let deleteMember = async (userId: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/DeleteMember/${userId}`, {
        method: "DELETE",
    })
}
export let inviteMember = async (req: InviteReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/InviteMember`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateMemberRole = async (userId: string, req: MemberRoleReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/UpdateRole/${userId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let addActiveCurrency = async (req: CurrencyActiveReq) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/Currencies/Active/Add`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteActiveCurrency = async (code: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/Currencies/Active/${code}/Delete`, {
        method: "DELETE",
    })
}
export let getCompanyCategory = async () => {
    return await customFetch(`CategoryCompany/`)
}
export let updateProductAddOnSetting = async (req: ProductAddOnSetting) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    return await customFetch(`Company/${companyID}/UpdateProductAddOnSetting`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let getSpecialPermissions = async (permission: string) => {
    return await customFetch(`Permission/Special?group=${permission}`)
}
export let getCustomerServices = async () => {
    return await customFetch(`CustomerService`)
}
export let getCurrencies = async (isTaxCurrency?: string) => {
    var params: Record<string, string> = {
        
    };
    if (isTaxCurrency) {
        params["is_tax_currency"] = isTaxCurrency 
    }
    // console.log(isTaxCurrency,params)
    return await customFetch(`Company/Currencies?${new URLSearchParams(params)}`)
}
export let getCurrencyDetail = async (code: string, toCurrencyCode? : string | null) => {
    return await customFetch(`Company/Currencies/${code}${toCurrencyCode ? `?to_currency=${toCurrencyCode}` : ''}`)
}
export let getMyPermissions = async () => {
    return await customFetch(`My/Permissions`)
}
export let getProductCategoryByName = async (name: string) => {
    return await customFetch(`Product/ProductCategoryByName/${name}`)
}
export let getPriceCategoryByName = async (name: string) => {
    return await customFetch(`Product/PriceCategoryByName/${name}`)
}
export let getStoreByName = async (name: string) => {
    return await customFetch(`Product/StoreByName/${name}`)
}
export let getUnitByName = async (name: string) => {
    return await customFetch(`Product/UnitByName/${name}`)
}
export let getPackagingByName = async (name: string) => {
    return await customFetch(`Product/PackagingByName/${name}`)
}
export let generateNumber = async (setting: BusinessSetting | AutoNumberSetting, featureType: string) => {
    return await customFetch(`Company/GenerateNumber?type=${featureType}`, {
        method: "POST",
        body: JSON.stringify(setting)
    })
}
export let getMyAddOn = async (addOnType: string) => {
    return await customFetch(`AddOn/My?=${addOnType}`)
}
export let updateTemplate = async (uuid: string) => {
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    return await customFetch(`Company/${companyID}/Template`, {
        method: "PUT",
        body: JSON.stringify({
            add_on_id: uuid
        })
    })
}

