import { useEffect, useState, type FC } from "react";
import { getCompany, getProfileStorage } from "../utils/helper";
import { Profile } from "../model/profile";
import { getProfile } from "../repositories/my";
import { CompanySimple } from "../model/company";

interface MainLayoutProps {
  children: React.ReactNode;
  onLoadProfile?: (v: Profile | null) => void;
  onLoadCompany?: (v: CompanySimple | null) => void;
}

const MainLayout: FC<MainLayoutProps> = ({ children, onLoadProfile, onLoadCompany }) => {
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [company, setCompany] = useState<CompanySimple | null>(null);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getProfileStorage()
      .then((v) => {
        // console.log(v)
        setProfile(v);
      });
      getCompany()
      .then((v) => {
        // console.log(v)
        setCompany(v);
      });
  }, [mounted]);

  useEffect(() => {
    if (onLoadProfile && profile) {
      onLoadProfile(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (onLoadCompany && company) {
      onLoadCompany(company);
    }
  }, [company]);
  return <div className=" w-full h-full">{children}</div>;
};
export default MainLayout;
