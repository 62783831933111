import { customFetch } from "../utils/helper";

export let sendPrompt = async (command: string, url: string, file_type: string) => {
   
    return await customFetch(`Gpt/Chat`, {
        method: "POST",
        body: JSON.stringify({command, url, file_type})
    })
}

export let getGPTHistories = async (isBookmarked: boolean) => {
   
    return await customFetch(`Gpt/Chat/History${isBookmarked ? '?bookmark' : ''}`)
}

export let deleteHistory = async (id: string) => {
   
    return await customFetch(`Gpt/Chat/History/${id}`, {
        method: "DELETE"
    })
}
export let setBookmarkHistory = async (id: string) => {
   
    return await customFetch(`Gpt/Chat/History/${id}/Bookmark`, {
        method: "put"
    })
}
export let clearHistory = async () => {
   
    return await customFetch(`Gpt/Chat/History/Clear`, {
        method: "POST"
    })
}