import { PaginationReq } from "../model/pagination"
import { DeletionReq, EditProfileReq, PasswordReq } from "../model/profile"
import { ReimbursementReq } from "../model/reimbursement"
import { customFetch } from "../utils/helper"

export let getProfile = async (token?: string) => {
    return await customFetch("My/Profile")
}
export let updateProfile = async (req: EditProfileReq) => {
    return await customFetch("My/Profile", {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let changePassword = async (req: PasswordReq) => {
    return await customFetch("My/Profile/ChangePassword", {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let getPermissions = async (token?: string | null, companyID?: string | null) => {
    console.log("token", token)
    return await customFetch("My/Permissions", {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "ID-Company": companyID
        }
    })
}
export let getMyReimbursementDetail = async (uuid: string) => {
    return await customFetch(`My/Reimbursement/${uuid}`)
}
export let createMyReimbursement = async (req: ReimbursementReq) => {
    return await customFetch(`My/Reimbursement`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let getPayslips = async () => {
    return await customFetch("My/PaySlip")
}
export let getMyReimbursement = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    return await customFetch(`My/Reimbursement?${new URLSearchParams(params)}`)
}
export let getPayslipDownload = async (uuid: string) => {
    return await customFetch(`My/PaySlip/${uuid}/Download`, {
        method: "POST",
    })
}
export let requestDeletion = async (req: DeletionReq) => {
    return await customFetch("UserDeletionRequest/", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let myRequestDeletion = async () => {
    return await customFetch("UserDeletionRequest/MyDetail")
}

export let getMyUserInvoices = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    return await customFetch(`Package/UserInvoice?${new URLSearchParams(params)}`)
}