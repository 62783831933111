import { SELECTED_COMPANY_ID, TOKEN } from "../objects/constants";
import { asyncLocalStorage, customFetch } from "./helper";


export let fileUpload = async (url:string, { image, dir, method } :  { image: File, dir: string, method?: string }) : Promise<Record<string, any>> => {

    let formData = new FormData();

    formData.append("image", image);
    formData.append("dir", dir);
    return customFetch(url, {
        method: method ?? 'POST',
        body: formData
    }, true)
    // let response = await resp.json()
    // if (resp.ok) {
    //     return Promise.resolve({
    //         "message": response.message,
    //         "url": response.data.url,
    //         "path": response.data.path,
    //     })
    // }
    // return Promise.reject({ "error": response.error })
}


export let blobUpload = async (url: string, { blob, dir, method }: { blob: Blob, dir: string, method?: string }): Promise<Record<string, any>> => {
    const token = await asyncLocalStorage.getItem(TOKEN)
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)

    const headers = new Headers({
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `attachment; filename="${dir}"`, // optional
        authorization: `Bearer ${token ?? null}`,
        "ID-Company": companyID,
    });

    return customFetch(url, {
        method: method ?? 'POST',
        headers,
        body: blob
    }, true)
}
