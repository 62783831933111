import type { FC } from 'react';
import { hint } from '../objects/chatMessage';
import { RiLightbulbLine } from 'react-icons/ri';
import { BsCardChecklist, BsQuestionCircle } from 'react-icons/bs';
import { BiCommand } from 'react-icons/bi';
import { TbInvoice } from 'react-icons/tb';
import { IoAttachOutline } from 'react-icons/io5';
import { randomStr } from '../utils/helper';

interface HintBoxProps {
    onClick: (h: hint) => void
}

const HintBox: FC<HintBoxProps> = ({onClick}) => {
    const hints: hint[] = [
        {
          text: "Berikan Inspirasi Bisnis Hari Ini",
          icon: <RiLightbulbLine size={16} className="text-orange-400" />,
        },
        {
          text: "Apa yang dimaksud dengan Cash Flow",
          icon: <BsQuestionCircle size={16} className="text-blue-400" />,
        },
        {
          text: "Buatkan Transaksi Pembelian Listrik sebesai 100000 dari Kas Kecil",
          icon: <BiCommand size={16} className="text-red-400" />,
        },
        {
          text: "Tampilkan 10 Transaksi bulan lalu",
          icon: <BsCardChecklist size={16} className="text-purple-500" />,
        },
        {
          text: "Buatkan faktur penjualan untuk pak Bambang dengan item berikut ini : 1 pasang Sepatu Olahraga Harga 500000 dan 5 Raket Badminton Harga 1500000 berikan diskon 20%",
          icon: <TbInvoice size={16} className="text-teal-600" />,
        },
        {
          text: "Buatkan faktur pembelian berdasarkan foto ini",
          icon: <IoAttachOutline size={16} className="text-lime-500" />,
          url: "https://api.jaraya.id/assets/images/receipt-with-calculated-tip-sample-min.jpg"
        },
      ];
      
        return (
            <div className="grid grid-cols-3 gap-4 w-full">
              {hints.map((e) => (
                <div
                  className=" aspect-square border rounded-2xl bg-white shadow-lg shadow-gray-200  flex flex-col justify-between p-4 cursor-pointer"
                  key={randomStr(6)}
                  onClick={() => onClick(e)}
                >
                  {e.icon}
                  <div className="text-xs h-[48px] line-clamp-3">{e.text}</div>
                </div>
              ))}

          
            </div>
        );
}
export default HintBox;