import { useEffect, useState, type FC } from "react";
import { LuSkipForward } from "react-icons/lu";

interface TypeEffectProps {
  text: string;
  onTyping: () => void;
  onFinished: () => void;
}

const TypeEffect: FC<TypeEffectProps> = ({ text, onTyping, onFinished }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [textToDisplay, setTextToDisplay] = useState(" ");
  const [isTyping, setIsTyping] = useState(false);
  const [stopWriting, setStopWriting] = useState(false);

  let index = -1;
  let interval: NodeJS.Timer;
  useEffect(() => {
    setTextToDisplay("  " + text);
  }, [text]);

  useEffect(() => {
    interval = setInterval(() => {
      if (index < textToDisplay.length) {
        if (textToDisplay[index]) {
          setDisplayedText((prev) => prev + textToDisplay[index]);
        }
        index += 1;
        if (index == textToDisplay.length) {
          setIsTyping(false);
          onFinished();
        } else {
          onTyping();
          setIsTyping(true);
        }
      } else {
        clearInterval(interval);
      }
    }, 30);

    return () => clearInterval(interval);
  }, [textToDisplay]);
  if (text.length == 0) return <div></div>;
  return (
    <>
      <div
        className={`min-w-[40%] max-w-[90%] mb-4 p-4 rounded-lg ${"bg-white text-gray-700 border self-start answer-wrapper"}`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              "<div>" + displayedText.replaceAll("undefined", "") + "</div>",
          }}
        />

        
      </div>
    </>
  );
};
export default TypeEffect;
