
import * as CryptoJS from 'crypto-js';
import { PERMISSIONS, SELECTED_COMPANY_ID, BOOKMARKS, SELECTED_COMPANY, TOKEN, SELECTED_MERCHANT_ID, SELECTED_MERCHANT, PROFILE, COMPANIES, SELECTED_MACHINE, DATERANGE, END_DATE, CURRENCY } from "../objects/constants";
import Swal from "sweetalert2";
import moment from 'moment';

export async function customFetch(...args) {
    let [resource, config, multipart, fullUrl] = args;
    const token = await asyncLocalStorage.getItem(TOKEN)
    const companyID = await asyncLocalStorage.getItem(SELECTED_COMPANY_ID)
    const merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    const currency = await getStorageCurrency()

    if (!config) {
        config = {
            headers: {
                authorization: `Bearer ${token ?? null}`
            }
        }
    } else {
        if (!config["headers"]) {
            config["headers"] = {
                authorization: `Bearer ${token ?? null}`
            }
        }

    }


    if (!multipart) {
        config["headers"]["Content-Type"] = "application/json"
    }
    if (companyID) {
        config["headers"]["ID-Company"] = companyID
    }
    if (merchantID) {
        config["headers"]["ID-Merchant"] = merchantID
    }
    config["headers"]["App"] = "jaraya-miqro"
    config["headers"]["Currency"] = currency

    try {
        // request interceptor here
        const response = await fetch(fullUrl ? resource : `${process.env.REACT_APP_API_URL}/${resource}`, config);

        if (response.status !== 200) {
            var respJson = await response.json()
            throw (respJson.message)
        }

        // response interceptor here
        return response;
    } catch (error) {

        if (error == "Token is expired") {
            await clearStorage()
            window.location.href = "/"
        }
        if (error == "No Company ID") {
        }
        return Promise.reject(error)
    }


}



export async function clearStorage() {
    await asyncLocalStorage.removeItem(PROFILE);
    await asyncLocalStorage.removeItem(COMPANIES);
    await asyncLocalStorage.removeItem(TOKEN);
    await asyncLocalStorage.removeItem(PERMISSIONS);
    await asyncLocalStorage.removeItem(SELECTED_COMPANY_ID);
    await asyncLocalStorage.removeItem(SELECTED_COMPANY);
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT_ID);
    await asyncLocalStorage.removeItem(SELECTED_MERCHANT);
    // await asyncLocalStorage.removeItem(SAVED);
    // await asyncLocalStorage.removeItem(SAVED_SALES);
}

export async function setStorageMachine(machine) {
    await asyncLocalStorage.setItem(SELECTED_MACHINE, JSON.stringify(machine));
}
export function getStorageMachine() {
    let machine = asyncLocalStorage.getItem(SELECTED_MACHINE)
    if (machine) return JSON.parse(machine)
    return null
}

export async function deleteStorageMachine() {
    return await asyncLocalStorage.removeItem(SELECTED_MACHINE);
}

export async function setStorage({ token, permissions, company }) {
    await asyncLocalStorage.setItem(TOKEN, token);
    await asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
    await asyncLocalStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
    await asyncLocalStorage.setItem(SELECTED_COMPANY_ID, company.uuid);
}

export async function setStorageToken(token) {
    await asyncLocalStorage.setItem(TOKEN, token);
}
export async function setStorageCurrency(currency) {
    await asyncLocalStorage.setItem(CURRENCY, currency);
}
export async function getStorageBookmarks() {
    let bookmarks = await asyncLocalStorage.getItem(BOOKMARKS);
    if (bookmarks) return JSON.parse(bookmarks)
    return []
}
export async function setStorageBookmarks(bookmarks) {
    // console.log(bookmarks)
    await asyncLocalStorage.setItem(BOOKMARKS, JSON.stringify(bookmarks));
}

export async function getStorageCurrency() {
    var curr = await asyncLocalStorage.getItem(CURRENCY);
    if (curr) return curr
    return "IDR"
}

export async function asyncSetStorage({ token, permissions, company }) {
    await asyncLocalStorage.setItem(TOKEN, token);
    await asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
    await asyncLocalStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
    await asyncLocalStorage.setItem(SELECTED_COMPANY_ID, company.uuid);
}
export async function setCompanyStorage(company) {
    await asyncLocalStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
    await asyncLocalStorage.setItem(SELECTED_COMPANY_ID, company.uuid);
}
export async function setMyPermissions(permissions) {
    asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
}
export async function setMyCompanies(companies) {
    asyncLocalStorage.setItem(COMPANIES, JSON.stringify(companies));
}
export async function getMyCompanies() {
    let companies = await asyncLocalStorage.getItem(COMPANIES)
    if (companies) return JSON.parse(companies)
    return []
}

export async function setProfileStorage(profile) {
    await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile));
}
export async function getProfileStorage() {
    let profile = await asyncLocalStorage.getItem(PROFILE);
    if (profile) return JSON.parse(profile)
    return null
}

export async function setActiveMerchant(merchant) {
    await asyncLocalStorage.setItem(SELECTED_MERCHANT_ID, merchant.uuid);
    await asyncLocalStorage.setItem(SELECTED_MERCHANT, JSON.stringify(merchant));
}

export async function getCompany() {
    let company = await asyncLocalStorage.getItem(SELECTED_COMPANY)
    if (company) return JSON.parse(company)
    return null
}

export async function getPermissions() {
    let permissions = await asyncLocalStorage.getItem(PERMISSIONS)
    if (permissions) return JSON.parse(permissions)
    return []
}

export async function getSelectedMerchantID() {
    return await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
}
export async function getSelectedMerchant() {
    let merchant = await asyncLocalStorage.getItem(SELECTED_MERCHANT)
    if (merchant) return JSON.parse(merchant)
    return null
}

export function initials(name) {
    if (!name) return ""
    return name.split(" ").map((n) => `${n[0]}`.toUpperCase()).join("");
}

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function ucFirstWord(string) {
    return string.replaceAll("_", " ").split(" ").map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");
}
export function parseVarian(val, valueOnly) {
    if (!val) return ""
    let keys = Object.keys(val)
    let values = Object.values(val)
    let parsed = []
    for (const i in values) {
        if (Object.hasOwnProperty.call(values, i)) {
            const element = values[i];
            if (valueOnly) {
                parsed.push(`${element}`)
            } else {
                parsed.push(`${keys[i]} : ${element}`)
            }
        }
    }
    return parsed.join(", ")
}

export function setNullString(val) {
    return { Valid: val != null && val != "", String: val }
}
export function randomStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}



export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


export const getToken = () => {
    let data = localStorage.getItem(TOKEN) || "";
    return decrypt(data) || null;
}
export const asyncLocalStorage = {
    async setItem(key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, encrypt(value));
        });
    },
    async getItem(key) {
        return Promise.resolve().then(function () {
            let data = localStorage.getItem(key) || "";
            return decrypt(data) || null;
        });
    },
    async removeItem(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    }
};


export function confirmDelete(confirmed, title, desc, confirmText) {
    Swal.fire({
        title: title ?? "Anda Yakin",
        text: desc ?? "Anda tidak akan dapat mengembalikan proses ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText ?? "Ya, Hapus!",
        cancelButtonText: "Batal"
    }).then((result) => {
        if (result.isConfirmed) {
            confirmed()
        }
    });
}

// export async function setSavedStorage(saved) {
//     // console.log("save saved", saved)
//     await asyncLocalStorage.setItem(SAVED, JSON.stringify(saved));
// }
// export async function getSavedStorage() {
//     let saved = await asyncLocalStorage.getItem(SAVED)
//     if (saved) return JSON.parse(saved)
//     return []
// }

export async function setStorageDateRange(dateRange) {
    return await asyncLocalStorage.setItem(DATERANGE, JSON.stringify([dateRange[0], dateRange[1]]));
}
export async function setStorageEndDate(date) {
    return await asyncLocalStorage.setItem(END_DATE, date);
}
export async function getStorageEndDate() {
    return await asyncLocalStorage.getItem(END_DATE);
}
export async function getStorageDateRange() {
    let dr = await asyncLocalStorage.getItem(DATERANGE)
    if (dr) return JSON.parse(dr)
    return null;
}

function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, process.env.REACT_APP_SECRET_KEY).toString();
}

function decrypt(txtToDecrypt) {
    return CryptoJS.AES.decrypt(txtToDecrypt, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}


export const rounded = (num) => {
    return Math.round(num * 100) / 100
}
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const zeroPad = (num, places) => String(num).padStart(places, '0')


export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function parseTime(time) {
    const now = moment();
    const inputTime = moment(time);

    if (now.isSame(inputTime, 'day')) {
        return inputTime.format('HH:mm');
    } else {
        return inputTime.format('DD MMM YYYY HH:mm');
    }
}

export const money = (val, friction = 2) => {
    if (!val) return 0
    return val.toLocaleString('id-ID', { maximumFractionDigits: friction });
}